/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import queryString from 'querystring';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import nProgress from 'nprogress';
import Pagination from '@mui/material/Pagination';
import Router from 'next/router';
import { useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import Cookies from 'lib/js-cookie';
import { translate as t, getCurrency } from 'utils/helpers';
import ConfirmationPopup from 'components/Toast/ConfirmationPopup';
import UserContext from 'context/UserContext';
import { getStripeDonationPurchaseHandler } from 'utils/stripe';
import useLang from '../../context/LanguageContext';
import styles from './CreatorLanding.module.scss';
import MyPodcasts from '../MyPodcasts';
import { allAudioList } from '../../apis/podcast';
import PublicFooter from '../Footer/PublicFooter';
import { subscribeCreator } from '../../apis/creator-page';
import routes from '../../utils/routes';
import PublicTopNavigation from '../Navigation/PublicTopNavigation';
import { getCampaignListByCreator } from '../../apis/newsletter';
import SharePopup from '../Toast/SharePopup';

const CreatorLandingPage = (props) => {
  const {
    creatorSubdomain,
    creatorData,
    host,
    isShare,
    token,
    showDonationConfirmation,
    cookieDonationObj,
  } = props;
  const lang = creatorData?.creator_language;
  const [userData, setUserData] = useContext(UserContext);

  const [uploadingAudioStatus, setUploadingAudioStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDonation, setLoadingDonation] = useState(false);
  const [podcastsList, setPodcastsList] = useState([]);
  const [podcastsListPageVise, setPodcastsListPageVise] = useState([]);
  const [podcastCount, setPodcastCount] = useState([]);
  const [podcastLoading, setPodcastLoading] = useState(false);
  const [latestNewsletters, setLatestNewsletters] = useState([]);
  const [donationTypeList, setDonationTypeList] = useState([]);
  const [selectedDonationProduct, setSelectedDonationProduct] = useState();
  const [donatePrice, setDonatePrice] = useState(3);
  const [customPrice, setCustomPrice] = useState('');
  const [donatePriceType, setDonatePriceType] = useState('defined');
  const [showConfirmationBox, setShowConfirmationBox] = useState(
    !!showDonationConfirmation,
  );
  const [message, setMessage] = useState();
  const [priceError, setPriceError] = useState(null);
  const latestNewslettersCount = 4;

  const { errors, register, handleSubmit, reset, setError } = useForm({});

  const {
    errors: errors2,
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    setError: setError2,
  } = useForm({});

  const stripe = useStripe();

  const formRef = useRef(null);
  const formRef2 = useRef(null);
  const pageSize = 12;
  const [page, setPage] = useState(1);

  const [modalShow, setModalShow] = useState(!!isShare);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const socialUrls = [];
  if (creatorData?.instagram) {
    socialUrls.push(creatorData?.instagram);
  }
  if (creatorData?.bluesky) {
    socialUrls.push(creatorData?.bluesky);
  }
  if (creatorData?.twitter) {
    socialUrls.push(creatorData?.twitter);
  }
  if (creatorData?.tik_tok) {
    socialUrls.push(creatorData?.tik_tok);
  }
  if (creatorData?.you_tube) {
    socialUrls.push(creatorData?.you_tube);
  }
  if (creatorData?.telegram) {
    socialUrls.push(creatorData?.telegram);
  }
  if (creatorData?.linkedin) {
    socialUrls.push(creatorData?.linkedin);
  }

  useEffect(() => {
    if (window !== undefined) {
      const temp = window?.location?.href;
      setRedirectUrl(temp);
    }
  }, []);

  useEffect(() => {
    if (creatorSubdomain) {
      getPodcastList();
    }
  }, [creatorSubdomain]);

  useEffect(() => {
    if (cookieDonationObj || Cookies.get('donationObj')) {
      const userSubscribed =
        cookieDonationObj?.user_subscribed ||
        (Cookies.get('donationObj') &&
          JSON.parse(Cookies.get('donationObj'))?.user_subscribed);
      let messageText = t(lang, 'creator')('confirmYourDonationPlease');
      if (userSubscribed) {
        messageText = t(
          lang,
          'creator',
        )('yourMonthlyDonationIsAlreadyActiveForThisCreator');
      } else if (userData?.isCardExpired) {
        messageText = t(lang, 'podcast')('cardExpiredMessage');
      }

      let submitButton = t(lang, 'creator')('goAhead');
      if (userSubscribed) {
        submitButton = t(lang, 'creator')('goToMyFeed');
      } else if (userData?.cardExpiresMessage) {
        submitButton = t(lang, 'podcast')('updateCreditCardButton');
      }
      setMessage({
        type: 'donate',
        title: t(lang, 'creator')('cool'),
        message: messageText,
        cardExpiresMessage: userData?.isCardExpired,
        user_subscribed:
          cookieDonationObj?.user_subscribed ||
          JSON.parse(Cookies.get('donationObj'))?.user_subscribed,
        submitButton,
        handleClose: () => {
          if (
            process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
          ) {
            Cookies.remove('showDonationConfirmation', { path: '/' });
            Cookies.remove('donationObj', { path: '/' });
          } else {
            Cookies.remove('showDonationConfirmation', {
              path: '/',
              domain: '.mumbler.io',
            });
            Cookies.remove('donationObj', { path: '/', domain: '.mumbler.io' });
          }
        },
      });
    }
  }, [cookieDonationObj]);
  useEffect(() => {
    if (creatorData?.user) {
      getLatestNewsletters(creatorData?.user);
    }
    if (creatorData?.donation_products?.length) {
      setDonationTypeList(
        creatorData?.donation_products?.map((product) => ({
          name:
            product?.type === 1
              ? t(lang, 'creator')('puntual')
              : t(lang, 'creator')('monthly'),
          type: product?.type,
          id: product?.id,
          domain_redirect_url: product?.domain_redirect_url,
          user_subscribed: product?.user_subscribed,
        })),
      );
      if (
        !Cookies.get('showDonationConfirmation') ||
        !selectedDonationProduct
      ) {
        setSelectedDonationProduct({
          name:
            creatorData?.donation_products[1]?.type === 1
              ? t(lang, 'creator')('puntual')
              : t(lang, 'creator')('monthly'),
          type: creatorData?.donation_products[1]?.type,
          id: creatorData?.donation_products[1]?.id,
          domain_redirect_url:
            creatorData?.donation_products[1]?.domain_redirect_url,
          user_subscribed: creatorData?.donation_products[1]?.user_subscribed,
          price: donatePrice,
        });
      }
    }
  }, [creatorData]);

  useEffect(() => {
    if (podcastsList?.length > 0) {
      const tempData = podcastsList?.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize,
      );
      setPodcastsListPageVise(tempData);
    }
  }, [page, podcastsList]);

  useEffect(() => {
    if (
      showDonationConfirmation ||
      Boolean(Cookies.get('showDonationConfirmation'))
    ) {
      setSelectedDonationProduct(JSON.parse(Cookies.get('donationObj')));
      setShowConfirmationBox(true);
    }
  }, [showDonationConfirmation]);

  useEffect(() => {
    if (Cookies.get('donationSuccessMsg')) {
      setUserData((prevState) => ({
        ...prevState,
        showModal: true,
        title: t(lang, 'common')('done'),
        message: t(lang, 'creator')('thanksForDonating'),
        closeActionCallback: () => {
          if (
            process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
          ) {
            Cookies.remove('donationSuccessMsg', { path: '/' });
          } else {
            Cookies.remove('donationSuccessMsg', {
              path: '/',
              domain: '.mumbler.io',
            });
          }
        },
      }));
    }
  }, [Cookies.get('donationSuccessMsg')]);
  const handlePageChange = (event, currentPage) => {
    setPage(currentPage);
  };

  const getPodcastList = async () => {
    setPodcastLoading(true);
    const response = await allAudioList(false, false);
    if (response?.status === 200) {
      setPodcastsList(response?.data?.my_audios);
      setPodcastCount(response?.data?.my_audios?.length);
    }
    setPodcastLoading(false);
  };

  const getLatestNewsletters = async (userId) => {
    nProgress.start();
    const response = await getCampaignListByCreator(
      userId,
      latestNewslettersCount,
    );
    if (response?.status === 200) {
      setLatestNewsletters(response?.data);
    }
    nProgress.done();
  };

  const handleSubscribeClick = async (data) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    const response = await subscribeCreator(formData);
    if (response?.status === 200 || response?.status === 201) {
      toast.success('Subscribed Successfully');
      reset();
    } else {
      for (const key in response?.data) {
        setError(key, {
          type: 'manual',
          message: response?.data[key][0],
        });
      }
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubscribeClick2 = async (data) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    const response = await subscribeCreator(formData);
    if (response?.status === 200 || response?.status === 201) {
      toast.success('Subscribed Successfully');
      reset2();
    } else {
      for (const key in response?.data) {
        setError2(key, {
          type: 'manual',
          message: response?.data[key][0],
        });
      }
      formRef2.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window?.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const handleDonationProduct = (product) => {
    setSelectedDonationProduct({ ...selectedDonationProduct, ...product });
  };

  const handleCustomPriceChange = (price, type) => {
    setDonatePriceType(type);
    setDonatePrice(parseInt(price, 10));
    setSelectedDonationProduct({ ...selectedDonationProduct, price });
    if (type === 'custom') {
      setCustomPrice(price);
      if (!price) {
        setDonatePrice(3);
        setSelectedDonationProduct({ ...selectedDonationProduct, price: 3 });
        setDonatePriceType('defined');
      }
    } else {
      setCustomPrice('');
    }
  };

  const handleDonate = (donationObj) => {
    setLoadingDonation(true);
    if (donationObj?.price < 1 || donationObj?.price > 500) {
      let messageText;
      if (donationObj?.price < 1) {
        messageText = t(lang, 'creator')('donationAmountShouldNotBeLessThan1');
      } else {
        messageText = t(
          lang,
          'creator',
        )('donationAmountShouldNotBeMoreThan500');
      }
      setPriceError({
        messageText,
      });
      return false;
    }
    setPriceError(null);

    if (token) {
      if (!userData?.stripe_payment_method_id?.length) {
        if (
          process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
        ) {
          Cookies.set('donationObj', JSON.stringify(donationObj), {
            path: '/',
          });
        } else {
          Cookies.set('donationObj', JSON.stringify(donationObj), {
            path: '/',
            domain: '.mumbler.io',
          });
        }
        Router.push(`${routes.donationPayment}?dp=${donationObj?.id}`);
      } else {
        let message = t(lang, 'creator')('confirmYourDonationPlease');
        if (donationObj?.user_subscribed) {
          message = t(
            lang,
            'creator',
          )('yourMonthlyDonationIsAlreadyActiveForThisCreator');
        } else if (userData?.isCardExpired) {
          message = t(lang, 'podcast')('cardExpiredMessage');
        }

        let submitButton = t(lang, 'creator')('goAhead');
        if (donationObj?.user_subscribed) {
          submitButton = t(lang, 'creator')('goToMyFeed');
        } else if (userData?.cardExpiresMessage) {
          submitButton = t(lang, 'podcast')('updateCreditCardButton');
        }

        setMessage({
          type: 'donate',
          title: t(lang, 'creator')('cool'),
          message,
          cardExpiresMessage: userData?.isCardExpired,
          user_subscribed: donationObj?.user_subscribed,
          submitButton,
        });
        setShowConfirmationBox(true);
      }
    } else {
      if (
        process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
      ) {
        Cookies.set('donationObj', JSON.stringify(donationObj), { path: '/' });
      } else {
        Cookies.set('donationObj', JSON.stringify(donationObj), {
          path: '/',
          domain: '.mumbler.io',
        });
      }
      Router.push(`${routes.register(lang)}?dp=${selectedDonationProduct?.id}`);
      setLoadingDonation(false);
    }
  };

  function purchaseSuccess(paymentIntent) {
    if (paymentIntent.status === 'succeeded') {
      setLoadingDonation(false);
      // setUserData((prevState) => ({
      //     ...prevState,
      //     showModal: true,
      //     title: t(lang, 'common')('done'),
      //     message: t(lang, 'creator')('thanksForDonating'),
      // }));
      if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.remove('donationObj', { path: '/' });
        Cookies.remove('showDonationConfirmation', { path: '/' });
        Cookies.set('donationSuccessMsg', true, { path: '/' });
      } else {
        Cookies.remove('donationObj', { path: '/', domain: '.mumbler.io' });
        Cookies.remove('showDonationConfirmation', {
          path: '/',
          domain: '.mumbler.io',
        });
        Cookies.set('donationSuccessMsg', true, {
          path: '/',
          domain: '.mumbler.io',
        });
      }
      Router.push(selectedDonationProduct?.domain_redirect_url);
    }
  }

  function purchaseError(error) {
    toast.error(error?.message);
    setLoadingDonation(false);
  }

  function addPrePurchaseChecks(funct) {
    // eslint-disable-next-line consistent-return
    function inner(...args) {
      setShowConfirmationBox(false);
      const tkn = Cookies.get('token');
      setLoadingDonation(true);
      if (tkn) {
        if (userData?.stripe_payment_method_id?.length) {
          funct(...args);
        } else {
          if (
            process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
          ) {
            Cookies.set(
              'donationObj',
              JSON.stringify(selectedDonationProduct),
              { path: '/' },
            );
          } else {
            Cookies.set(
              'donationObj',
              JSON.stringify(selectedDonationProduct),
              { path: '/', domain: '.mumbler.io' },
            );
          }
          Router.push(routes.donationPayment);
        }
      } else {
        if (
          process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
        ) {
          Cookies.set('donationObj', JSON.stringify(selectedDonationProduct), {
            path: '/',
          });
        } else {
          Cookies.set('donationObj', JSON.stringify(selectedDonationProduct), {
            path: '/',
            domain: '.mumbler.io',
          });
        }
        Router.push(
          `${routes.register(lang)}?dp=${selectedDonationProduct?.id}`,
        );
      }
    }

    return inner;
  }

  const donateToCreator = addPrePurchaseChecks(
    getStripeDonationPurchaseHandler(stripe, purchaseSuccess, purchaseError),
  );

  const updateCreditCard = () => {
    Router.push('/account/billing/payment-methods');
  };

  return (
    <div className="page nosidebar">
      <Head>
        <title>{`${creatorData?.name}: podcasts y newsletter | Mumbler creators`}</title>
        {/* <link rel="icon" href="/favicon.png" /> */}
        <link rel="canonical" href={`${creatorData?.domain}/`} />
        <meta name="description" content={creatorData?.about_text} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${creatorData?.name} : podcasts y newsletter | Mumbler creators`}
        />
        <meta
          property="og:description"
          name="description"
          content={creatorData?.about_text}
        />
        <meta property="og:url" content={`${creatorData?.domain}/`} />
        <meta property="og:image" content={creatorData?.profile_webp} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${creatorData?.name}: podcasts y newsletter | Mumbler creators`}
        />
        <meta name="twitter:description" content={creatorData?.about_text} />
        <meta name="twitter:url" content={`${creatorData?.domain}/`} />
        <meta name="twitter:image" content={creatorData?.profile_webp} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',

              '@type': 'Person',

              name: creatorData?.name,

              url: `${creatorData?.domain}/`,

              image: creatorData?.profile_webp,

              sameAs: socialUrls,
            }),
          }}
        />
        ;
      </Head>
      <main className="main">
        <PublicTopNavigation showLine lang={lang} redirectUrl={redirectUrl} />
        <Container className="wider mobile-full">
          <div className={styles.creatorWrapper}>
            {/* <div className={styles.creatorName}>
                            {creatorData?.name}
                        </div> */}
            <Row className={styles.aboutSection}>
              {creatorData?.profile_webp && (
                <Col md={6} className={styles.imageWrap}>
                  <img
                    className="img-fluid"
                    src={creatorData?.profile_webp}
                    alt=""
                    loading="lazy"
                  />
                </Col>
              )}
              <Col md={creatorData?.profile_webp ? 6 : 12}>
                <div
                  className={`${styles.content} ${
                    creatorData?.profile_webp ? 'ml-md-3' : ''
                  } ml-0 mt-3 mt-md-0`}>
                  <h1 className={styles.title}>{creatorData?.about_title}</h1>
                  <p className={styles.text}>{creatorData?.about_text}</p>
                </div>
                <div
                  className={`${styles.socialIconsWrap} ${
                    creatorData?.profile_webp ? 'ml-md-3' : ''
                  } ml-0`}>
                  {creatorData?.instagram && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.instagram || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/instagram-icon.svg"
                        className={styles.singleIcon}
                        alt="instagram icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {creatorData?.twitter && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.twitter || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/twitter-icon.svg"
                        className={styles.singleIcon}
                        alt="twitter icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {creatorData?.tik_tok && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.tik_tok || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/tiktok-icon.svg"
                        className={styles.singleIcon}
                        alt="tik-tok icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {creatorData?.you_tube && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.you_tube || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/youtube-icon.svg"
                        className={styles.singleIcon}
                        alt="youtube icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {creatorData?.telegram && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.telegram || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/telegram-icon.svg"
                        className={styles.singleIcon}
                        alt="telegram icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {creatorData?.linkedin && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.linkedin || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/linkedin-icon.svg"
                        className={styles.singleIcon}
                        alt="linkedin icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                  {creatorData?.bluesky && (
                    <a
                      className={`${styles.singleIconWrap} no-effect`}
                      href={creatorData?.bluesky || '#'}
                      rel="noreferrer"
                      target="_blank">
                      <img
                        src="/social-icons/bluesky-icon.svg"
                        className={styles.singleIcon}
                        alt="bluesky icon"
                        loading="lazy"
                      />
                    </a>
                  )}
                </div>
              </Col>
            </Row>

            {creatorData?.show_subscribe_form && (
              <Row className={styles.subscriptionSection} id="subscribe">
                <Col md={6}>
                  <div className={styles.content}>
                    <h2 className={styles.title}>
                      {creatorData?.form_cta_title}
                    </h2>
                    <p className={styles.text}>{creatorData?.form_cta_text}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <Form
                    className="form mt-0"
                    ref={formRef}
                    noValidate
                    onSubmit={handleSubmit(handleSubscribeClick)}>
                    <Form.Group className="floating">
                      <Form.Control
                        type="text"
                        ref={register({
                          required: 'Name is required',
                        })}
                        required
                        isInvalid={!!errors.name}
                        name="name"
                      />
                      <Form.Label>{t(lang, 'creator')('name')}</Form.Label>
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">
                      {errors?.name?.message}
                    </Form.Control.Feedback>

                    <Form.Group className="floating">
                      <Form.Control
                        type="text"
                        ref={register({
                          required: 'Email is required',
                          validate: (val) => {
                            const emailRegex =
                              /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            return emailRegex.test(val)
                              ? true
                              : t(lang, 'common')('invalidEmailError');
                          },
                        })}
                        required
                        isInvalid={!!errors?.name}
                        name="email"
                      />
                      <Form.Label>
                        {t(lang, 'creator')('emailAddress')}
                      </Form.Label>
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">
                      {errors?.email && errors?.email?.message}
                    </Form.Control.Feedback>

                    <Form.Check
                      type="checkbox"
                      name="terms_and_condition"
                      className="custom-checkbox mb-4"
                      ref={register({
                        required: t(lang, 'creator')('termsError'),
                      })}
                      isInvalid={!!errors?.terms_and_condition}
                      label={
                        <div>
                          <span>{t(lang, 'account')('termsAgree')} </span>
                          <a
                            href={`${routes.privacyPolicy(lang)}#terms`}
                            target="_blank">
                            {t(lang, 'account')('termsAnchorText')}
                          </a>
                        </div>
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.terms_and_condition &&
                        errors?.terms_and_condition?.message}
                    </Form.Control.Feedback>
                    <Button
                      id="subscribeButtonTop"
                      variant="primary"
                      type="submit"
                      className="btn-right"
                      style={{
                        color: creatorData?.text_color,
                        backgroundColor: creatorData?.button_color,
                        borderColor: creatorData?.button_color,
                      }}
                      disabled={loading || uploadingAudioStatus === 'loading'}>
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        creatorData?.form_cta_button ||
                        t(lang, 'common')('subscribe')
                      )}
                    </Button>
                  </Form>
                </Col>
              </Row>
            )}
            {podcastsList?.length > 0 && (
              <div className={styles.podcastSection}>
                <h2 className={styles.title}>
                  {t(lang, 'creator')('myPodcastsAndAudioSeries')}
                </h2>
                <MyPodcasts
                  lang={lang}
                  loadingStatus={podcastLoading}
                  data={podcastsListPageVise}
                  hideCreate
                  isCreatorPage
                />
                {podcastCount > pageSize && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '2.5rem ',
                    }}>
                    <Pagination
                      className="customPagination"
                      shape="rounded"
                      color="primary"
                      size="small"
                      count={Math.ceil(podcastCount / pageSize)}
                      page={page}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </div>
            )}
            {creatorData?.allow_donation && (
              <div className={styles.donationSection} id="donation">
                <h2 className={styles.title}>
                  {t(lang, 'creator')('donationTitle', {
                    name: creatorData?.name,
                  })}
                </h2>
                <p className={styles.text}>{creatorData?.donation_text}</p>
                <div className={styles.donactionCard}>
                  <div className={styles.donationTypes}>
                    {donationTypeList?.map((product) => (
                      <div
                        key={product?.id}
                        className={`${styles.type} ${
                          selectedDonationProduct?.type === product?.type
                            ? styles.active
                            : ''
                        }`}
                        onClick={() => handleDonationProduct(product)}>
                        {product?.name}
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.donationPriceSection} form`}>
                    <div className={styles.definedPrice}>
                      <div
                        className={`${styles.price} ${
                          donatePriceType === 'defined' && donatePrice === 3
                            ? styles.active
                            : ''
                        }`}
                        onClick={() => handleCustomPriceChange(3, 'defined')}>
                        {getCurrency(creatorData?.currency)}3
                      </div>
                      <div
                        className={`${styles.price} ${
                          donatePriceType === 'defined' && donatePrice === 5
                            ? styles.active
                            : ''
                        }`}
                        onClick={() => handleCustomPriceChange(5, 'defined')}>
                        {getCurrency(creatorData?.currency)}5
                      </div>
                      <div
                        className={`${styles.price} ${
                          donatePriceType === 'defined' && donatePrice === 10
                            ? styles.active
                            : ''
                        }`}
                        onClick={() => handleCustomPriceChange(10, 'defined')}>
                        {getCurrency(creatorData?.currency)}10
                      </div>
                    </div>
                    <Form.Group
                      className={`${styles.customPrice} floating mb-0`}>
                      <Form.Control
                        type="number"
                        name="price"
                        min="0"
                        onPaste={preventPasteNegative}
                        onKeyDown={preventMinus}
                        required
                        value={customPrice}
                        onChange={(e) =>
                          handleCustomPriceChange(e?.target?.value, 'custom')
                        }
                      />
                      <Form.Label>
                        {t(lang, 'podcast')('priceHeading')}
                      </Form.Label>
                    </Form.Group>
                  </div>
                </div>
                <div className="custom-error">{priceError?.message}</div>
                <Button
                  variant="primary"
                  type="button"
                  className="btn-right mt-4"
                  style={{
                    color: creatorData?.text_color,
                    backgroundColor: creatorData?.button_color,
                    borderColor: creatorData?.button_color,
                  }}
                  disabled={loadingDonation}
                  onClick={() => handleDonate(selectedDonationProduct)}>
                  {loadingDonation && <Spinner animation="grow" />}{' '}
                  {selectedDonationProduct?.type === 0
                    ? t(lang, 'creator')('helpWithPricePerMonth', {
                        price: donatePrice,
                        currency: getCurrency(creatorData?.currency),
                      })
                    : t(lang, 'creator')('helpWithPrice', {
                        price: donatePrice,
                        currency: getCurrency(creatorData?.currency),
                      })}
                </Button>
              </div>
            )}
            {creatorData?.show_newsletter && latestNewsletters?.count > 0 && (
              <div className={styles.newsletterSection}>
                <h2 className={styles.title}>
                  {t(lang, 'newsletter')('latestEditions')}
                </h2>
                <div className={styles.newslettersWrapper}>
                  {latestNewsletters?.results?.map((newsletter) => (
                    <a
                      href={newsletter?.domain_redirect_url || '#'}
                      target="_blank"
                      key={newsletter?.id}
                      className={styles.singleNewsletter}>
                      <div>{newsletter?.name}</div>
                      <div>Read</div>
                    </a>
                  ))}
                </div>
              </div>
            )}
            {(creatorData?.testimonial_tweet_1 ||
              creatorData?.testimonial_tweet_2 ||
              creatorData?.testimonial_tweet_3) && (
              <div className={styles.tweetsSection}>
                <h2 className={styles.title}>
                  {t(lang, 'creator')('thisIsWhatOthersHaveToSay')}
                </h2>
                <Row className={styles.allTweetsWrapper}>
                  {creatorData?.testimonial_tweet_1 && (
                    <Col lg={4} md={6} className={styles.singleTweet}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: creatorData?.testimonial_tweet_1,
                        }}></div>
                    </Col>
                  )}
                  {creatorData?.testimonial_tweet_2 && (
                    <Col lg={4} md={6} className={styles.singleTweet}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: creatorData?.testimonial_tweet_2,
                        }}></div>
                    </Col>
                  )}
                  {creatorData?.testimonial_tweet_3 && (
                    <Col lg={4} md={6} className={styles.singleTweet}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: creatorData?.testimonial_tweet_3,
                        }}></div>
                    </Col>
                  )}
                </Row>
              </div>
            )}
            {creatorData?.show_subscribe_form && (
              <Row className={styles.subscriptionSection}>
                <Col md={12}>
                  <div className={`${styles.content} text-md-center`}>
                    <h2 className={styles.title}>
                      {creatorData?.form_cta_title}
                    </h2>
                    <p className={styles.text}>{creatorData?.form_cta_text}</p>
                  </div>
                </Col>
                <Col md={12}>
                  <Form
                    className="form"
                    ref={formRef2}
                    noValidate
                    onSubmit={handleSubmit2(handleSubscribeClick2)}>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="floating">
                          <Form.Control
                            type="text"
                            ref={register2({
                              required: 'Name is required',
                            })}
                            required
                            isInvalid={!!errors2?.name}
                            name="name"
                          />
                          <Form.Label>{t(lang, 'creator')('name')}</Form.Label>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          {errors2?.name?.message}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="floating">
                          <Form.Control
                            type="text"
                            ref={register2({
                              required: 'Email is required',
                            })}
                            required
                            isInvalid={!!errors2?.name}
                            name="email"
                          />
                          <Form.Label>
                            {t(lang, 'creator')('emailAddress')}
                          </Form.Label>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          {errors2?.email?.message}
                        </Form.Control.Feedback>
                      </Col>
                      <Col xs={{ order: 2 }} md={{ span: 4, order: 1 }}>
                        <Button
                          id="subscribeButtonBottom"
                          variant="primary"
                          type="submit"
                          style={{
                            color: creatorData?.text_color,
                            backgroundColor: creatorData?.button_color,
                            borderColor: creatorData?.button_color,
                          }}
                          disabled={
                            loading || uploadingAudioStatus === 'loading'
                          }>
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            creatorData?.form_cta_button ||
                            t(lang, 'common')('subscribe')
                          )}
                        </Button>
                      </Col>
                      <Col
                        xs={{ span: 12, order: 1 }}
                        md={{ order: 2 }}
                        className="text-left text-md-center">
                        <Form.Check
                          type="checkbox"
                          name="terms_and_condition"
                          className="custom-checkbox mb-2 mb-md-0"
                          ref={register2({
                            required: t(lang, 'creator')('termsError'),
                          })}
                          isInvalid={!!errors2?.terms_and_condition}
                          label={
                            <div>
                              <span>{t(lang, 'account')('termsAgree')} </span>
                              <a
                                href={`${routes.privacyPolicy(lang)}#terms`}
                                target="_blank">
                                {t(lang, 'account')('termsAnchorText')}
                              </a>
                            </div>
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors2?.terms_and_condition &&
                            errors2?.terms_and_condition?.message}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
          </div>
        </Container>
        <PublicFooter lang={lang} langChanger={false} />

        <SharePopup
          show={modalShow}
          host={host}
          modalTitle={t(lang, 'common')('share')}
          shareText={creatorData?.about_title}
          shareUrl={creatorData?.domain}
          onHide={() => setModalShow(false)}
          hideVia
        />

        <ConfirmationPopup
          showConfirmation={showConfirmationBox}
          handleClose={() => {
            if (
              process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
              'http://localhost:3000'
            ) {
              Cookies.remove('showDonationConfirmation', { path: '/' });
              Cookies.remove('donationObj', { path: '/' });
            } else {
              Cookies.remove('showDonationConfirmation', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('donationObj', {
                path: '/',
                domain: '.mumbler.io',
              });
            }
            setLoadingDonation(false);
            setShowConfirmationBox(false);
          }}
          title={message?.title}
          message={message?.message}
          noNeedOfAlertIcon={message?.type === 'donate'}
          confirmActionCallback={() => {
            if (message?.type === 'donate') {
              if (message?.user_subscribed) {
                Router.push(routes.myFeeds);
              } else if (message?.cardExpiresMessage) {
                updateCreditCard();
              } else {
                if (
                  process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
                  'http://localhost:3000'
                ) {
                  Cookies.remove('showDonationConfirmation', { path: '/' });
                } else {
                  Cookies.remove('showDonationConfirmation', {
                    path: '/',
                    domain: '.mumbler.io',
                  });
                }
                donateToCreator(selectedDonationProduct);
              }
            }
          }}
          cancelButton={
            message?.type === 'unsubscribe' &&
            t(lang, 'podcast')('keepSubscription')
          }
          submitButton={message?.submitButton}
          submitButtonColor={message?.type === 'donate' && '#0d122a'}
        />

        {/* this script will allow tweets to be embeded after page is loaded(defer) */}
        <script defer src="https://platform.twitter.com/widgets.js"></script>
      </main>
    </div>
  );
};

CreatorLandingPage.propTypes = {
  creatorSubdomain: PropTypes.string,
  creatorData: PropTypes.object,
  host: PropTypes.string,
  isShare: PropTypes.bool,
  token: PropTypes.string,
  showDonationConfirmation: PropTypes.bool,
  cookieDonationObj: PropTypes.object,
};

export default CreatorLandingPage;
